<template>
  <div>
    <div class="nova-comunicacao-container">
      <h1>{{ camposInput.titulo }}</h1>
      <div class="formulario-comunicacao">
        <div class="formulario-linha">
          <inputValidate v-if="camposInput.camposDisponiveis.ordem" class="formulario-item formulario-comunicacao-ordem" :erro="ordemErro">
            <label class="formulario-item-label">Ordem <span v-if="verificaCampoObrigatorio('ordem')">*</span></label>
            <input type="number" min="0" max="99" v-mask="'99'" v-model.trim="form.ordem" @blur="$v.form.ordem.$touch()" />
          </inputValidate>
          <!--<inputValidate v-if="camposInput.camposDisponiveis.tipo" class="formulario-item formulario-comunicacao-tipo" :erro="tipoErro">
            <label class="formulario-item-label">Tipo <span v-if="verificaCampoObrigatorio('tipo')">*</span></label>
            <multiselect
              v-model="form.tipo"
              :options="[
                { name: 'Geral', id: 1 },
                { name: 'Participante', id: 2 }
              ]"
              label="name"
              track-by="id"
              :searchable="false"
              :allow-empty="false"
              placeholder="Selecione"
              select-label="Selecionar"
              deselect-label="Selecionado"
              selected-label="Selecionado"
              @close="fecharSelect('tipo')"
            />
          </inputValidate>-->
          <inputValidate v-if="camposInput.camposDisponiveis.url" class="formulario-item formulario-comunicacao-url" :erro="urlErro">
            <label class="formulario-item-label">URL <span v-if="verificaCampoObrigatorio('url')">*</span></label>
            <input type="text" placeholder="URL" v-model.trim="form.url" @blur="$v.form.url.$touch()" />
          </inputValidate>
          <inputValidate v-if="camposInput.camposDisponiveis.data" class="formulario-item formulario-comunicacao-periodo" :erro="dataErro">
            <label class="formulario-item-label">
              Período Ativo
              <transition name="fade">
                <span v-if="verificaCampoObrigatorio('data') && !form.permanente">*</span>
              </transition></label
            >
            <VueHotelDatepicker
              class="formulario-item"
              format="DD/MM/YYYY"
              :placeholder="calendarioTexto.placeholder"
              :separator="calendarioTexto.toText"
              :weekList="calendarioTexto.weekList"
              :monthList="calendarioTexto.monthList"
              :fromText="calendarioTexto.fromText"
              :toText="calendarioTexto.toText"
              :resetText="calendarioTexto.resetText"
              :confirmText="calendarioTexto.confirmText"
              :startDate="calendario.inicio"
              :endDate="calendario.final"
              @update="atualizaCalendario"
              @reset="resetCalendario"
              @close="closeCalendario"
              mobile="mobile"
            />
          </inputValidate>
          <inputValidate v-if="camposInput.camposDisponiveis.data" class="formulario-item formulario-comunicacao-permanente">
            <label class="label-nova-comunicacao formulario-item-label">Permanente</label>
            <input type="checkbox" id="input-comunicacao-permanente" v-model="form.permanente" />
            <div class="label-check-fake-container">
              <label class="label-check-fake" for="input-comunicacao-permanente">
                <span></span>
                <span></span>
              </label>
            </div>
          </inputValidate>
        </div>
        <!--<div class="formulario-linha" v-if="camposInput.camposDisponiveis.tipo && form.tipo.id == 2">
          <inputValidate class="formulario-item formulario-comunicacao-unidade-negocios" :erro="unidadeNegocioErro">
            <label class="formulario-item-label">Unidade de Negócio <span v-if="verificaCampoObrigatorio('tipo')">*</span></label>
            <multiselect v-model="form.unidadeNegocio" :options="['item01', 'item02', 'item03']" :multiple="true" :close-on-select="false" placeholder="Selecione" select-label="Selecionar" deselect-label="Remover" selected-label="Selecionado" @close="fecharSelect('unidadeNegocio')">
              <span slot="noResult">Sem informações.</span>
            </multiselect>
          </inputValidate>
          <inputValidate class="formulario-item formulario-comunicacao-redes" :erro="redesErro">
            <label class="formulario-item-label">Redes <span v-if="verificaCampoObrigatorio('tipo')">*</span></label>
            <multiselect v-model="form.redes" :options="['item01', 'item02', 'item03']" :multiple="true" :close-on-select="false" placeholder="Selecione" select-label="Selecionar" deselect-label="Remover" selected-label="Selecionado" @close="fecharSelect('redes')">
              <span slot="noResult">Sem informações.</span>
            </multiselect>
          </inputValidate>
          <inputValidate class="formulario-item formulario-comunicacao-cargos" :erro="cargosErro">
            <label class="formulario-item-label">Cargos <span v-if="verificaCampoObrigatorio('tipo')">*</span></label>
            <multiselect v-model="form.cargos" :options="['item01', 'item02', 'item03']" :multiple="true" :close-on-select="false" placeholder="Selecione" select-label="Selecionar" deselect-label="Remover" selected-label="Selecionado" @close="fecharSelect('cargos')">
              <span slot="noResult">Sem informações.</span>
            </multiselect>
          </inputValidate>
        </div>-->
        <div class="formulario-linha" v-if="camposInput.camposDisponiveis.bannerMobile || camposInput.camposDisponiveis.bannerDesk || camposInput.camposDisponiveis.banner">
          <inputValidate v-if="camposInput.camposDisponiveis.bannerMobile" class="formulario-item formulario-comunicacao-banner-mobile container-upload" :erro="imagemMobileErro">
            <label class="formulario-item-label">Banner Mobile <span v-if="verificaCampoObrigatorio('bannerMobile')">*</span></label>
            <input id="input-banner-mobile" type="file" @change="atualizarImagem('imagemMobile', 'dataImagemMobile', $event)" @blur="$v.form.imagemMobile.$touch()" />
            <label for="input-banner-mobile" class="upload-btn">Selecionar arquivo</label>
            <transition name="fade">
              <a v-if="dataImagemMobile != ''" class="remove-image" @click="removerImagem('imagemMobile', 'dataImagemMobile', 'input-banner-mobile')">Remover</a>
            </transition>
            <div class="upload-div" :style="imagemMobileBg"><span v-if="dataImagemMobile == ''">Visualização</span></div>
          </inputValidate>
          <inputValidate v-if="camposInput.camposDisponiveis.bannerDesk" class="formulario-item formulario-comunicacao-banner-desktop container-upload" :erro="imagemDesktopErro">
            <label class="formulario-item-label">Banner Desktop <span v-if="verificaCampoObrigatorio('bannerDesk')">*</span></label>
            <input id="input-banner-desktop" type="file" @change="atualizarImagem('imagemDesk', 'dataImagemDesk', $event)" @blur="$v.form.imagemDesk.$touch()" />
            <label for="input-banner-desktop" class="upload-btn">Selecionar arquivo</label>
            <transition name="fade">
              <a v-if="dataImagemDesk != ''" class="remove-image" @click="removerImagem('imagemDesk', 'dataImagemDesk', 'input-banner-desktop')">Remover</a>
            </transition>
            <div class="upload-div" :style="imagemDesktopBg"><span v-if="dataImagemDesk == ''">Visualização</span></div>
          </inputValidate>
          <inputValidate v-if="camposInput.camposDisponiveis.banner" class="formulario-item formulario-comunicacao-banner container-upload" :erro="imagemErro">
            <label class="formulario-item-label">Banner <span v-if="verificaCampoObrigatorio('banner')">*</span></label>
            <input id="input-banner" type="file" @change="atualizarImagem('imagem', 'dataImagem', $event)" @blur="$v.form.imagem.$touch()" />
            <label for="input-banner" class="upload-btn">Selecionar arquivo</label>
            <transition name="fade">
              <a v-if="dataImagem != ''" class="remove-image" @click="removerImagem('imagem', 'dataImagem', 'input-banner')">Remover</a>
            </transition>
            <div class="upload-div" :style="imagemBg"><span v-if="dataImagem == ''">Visualização</span></div>
          </inputValidate>
        </div>
        <div class="formulario-linha" v-if="camposInput.camposDisponiveis.tagueamento">
          <inputValidate class="formulario-item formulario-comunicacao-categoria" :erro="categoriaErro">
            <label class="formulario-item-label">Tag Categoria <span v-if="verificaCampoObrigatorio('tagueamento')">*</span></label>
            <input type="text" v-model.trim="form.tagueamentoCategoria" @blur="$v.form.tagueamentoCategoria.$touch()" />
          </inputValidate>
          <inputValidate class="formulario-item formulario-comunicacao-acao" :erro="acaoErro">
            <label class="formulario-item-label">Tag Ação <span v-if="verificaCampoObrigatorio('tagueamento')">*</span></label>
            <input type="text" v-model.trim="form.tagueamentoAcao" @blur="$v.form.tagueamentoAcao.$touch()" />
          </inputValidate>
        </div>
        <div class="formulario-linha" v-if="camposInput.camposDisponiveis.titulo">
          <inputValidate class="formulario-item formulario-comunicacao-titulo" :erro="tituloErro">
            <label class="formulario-item-label">Título <span v-if="verificaCampoObrigatorio('conteudo')">*</span></label>
            <input type="text" v-model.trim="form.titulo" @blur="$v.form.titulo.$touch()" />
          </inputValidate>
        </div>
        <div class="formulario-linha" v-if="camposInput.camposDisponiveis.conteudo">
          <inputValidate class="formulario-item formulario-comunicacao-conteudo" :erro="conteudoErro">
            <label class="formulario-item-label">Conteúdo <span v-if="verificaCampoObrigatorio('conteudo')">*</span></label>
            <tinymce id="d1" v-model="form.conteudo" @editorChange="$v.form.conteudo.$touch()" :other_options="{ language_url: './lang_tiny/pt_BR.js' }"></tinymce>
          </inputValidate>
        </div>
      </div>
      <p class="campos-obrigatorios">* Campos obrigatórios</p>
    </div>
    <div class="btns-formulario">
      <button class="btn btn-cancelar" @click="cancelarFormuario">Cancelar</button>
      <button class="btn btn-enviar" :disabled="$v.form.$invalid" @click="salvarFormuario">Salvar</button>
    </div>
  </div>
</template>

<script>
import inputValidate from "@/components/formularios/InputValidate.vue";
import VueHotelDatepicker from "@northwalker/vue-hotel-datepicker";
import tinymce from "vue-tinymce-editor";
// import Multiselect from "vue-multiselect";
import AwesomeMask from "awesome-mask";
import { requiredIf } from "vuelidate/lib/validators";
import * as vuelidate from "@/plugins/vuelidate/comunicacao";
import { comunicacaoAtualizar, comunicacaoEnviar } from "@/api/comunicacao";
import { stringToDatetime } from "@/utils/date";
import { _imagemMobile, _imagemDesktop } from "@/api/_caminhosApi";
import { returnValidUrl } from "@/utils/url";
export default {
  directives: {
    mask: AwesomeMask
  },
  components: {
    VueHotelDatepicker,
    inputValidate,
    tinymce,
    // Multiselect
  },
  data() {
    return {
      dataImagemDesk: "",
      dataImagemMobile: "",
      dataImagem: "",
      form: {
        ordem: 0,
        tipo: { name: "Geral", id: 1 },
        url: "",
        dataInicio: "",
        dataFinal: "",
        permanente: false,
        imagemMobile: "",
        imagemDesk: "",
        imagem: "",
        unidadeNegocio: "",
        redes: "",
        cargos: "",
        tagueamentoCategoria: "",
        tagueamentoAcao: "",
        conteudo: "",
        titulo: ""
      },
      calendario: {
        inicio: "",
        final: ""
      },
      calendarioTexto: {
        placeholder: "Selecione o período",
        fromText: "De",
        toText: "a",
        resetText: "Limpar",
        confirmText: "Confirmar",
        weekList: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        monthList: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
      }
    };
  },
  validations: {
    form: {
      ordem: {
        required: requiredIf(function() {
          return this.verificaCampoObrigatorio("ordem");
        })
      },
      url: {
        required: requiredIf(function() {
          return this.verificaCampoObrigatorio("url");
        })
      },
      dataInicio: {
        required: requiredIf(function(form) {
          return !form.permanente && this.verificaCampoObrigatorio("data");
        })
      },
      dataFinal: {
        required: requiredIf(function(form) {
          return !form.permanente && this.verificaCampoObrigatorio("data");
        })
      },
      imagemMobile: {
        required: requiredIf(function() {
          return !this.dataImagemMobile && this.verificaCampoObrigatorio("bannerMobile");
        })
      },
      imagemDesk: {
        required: requiredIf(function() {
          return !this.dataImagemDesk && this.verificaCampoObrigatorio("bannerDesk");
        })
      },
      imagem: {
        required: requiredIf(function() {
          return !this.dataImagem && this.verificaCampoObrigatorio("banner");
        })
      },
      tagueamentoCategoria: {
        required: requiredIf(function() {
          return this.verificaCampoObrigatorio("tagueamento");
        })
      },
      tagueamentoAcao: {
        required: requiredIf(function() {
          return this.verificaCampoObrigatorio("tagueamento");
        })
      },
      conteudo: {
        required: requiredIf(function() {
          return this.verificaCampoObrigatorio("conteudo");
        })
      },
      titulo: {
        required: requiredIf(function() {
          return this.verificaCampoObrigatorio("conteudo");
        })
      }
    }
  },
  computed: {
    ordemErro: vuelidate.ordemErro,
    tipoErro: vuelidate.tipoErro,
    urlErro: vuelidate.urlErro,
    dataErro: vuelidate.dataErro,
    unidadeNegocioErro: vuelidate.unidadeNegocioErro,
    redesErro: vuelidate.redesErro,
    cargosErro: vuelidate.cargosErro,
    imagemMobileErro: vuelidate.imagemMobileErro,
    imagemDesktopErro: vuelidate.imagemDesktopErro,
    imagemErro: vuelidate.imagemErro,
    categoriaErro: vuelidate.categoriaErro,
    acaoErro: vuelidate.acaoErro,
    tituloErro: vuelidate.tituloErro,
    conteudoErro: vuelidate.conteudoErro,
    camposInput() {
      console.log('camposInput', this.$store.state.comunicacao.componenteAtivado)
      return this.$store.state.comunicacao.componenteAtivado;
    },
    formularioTipo() {
      return this.$store.state.comunicacao.formularioTipo;
    },
    tabela() {
      return this.$store.state.comunicacao.tabela;
    },
    idTabelaAtivada() {
      return this.$store.state.comunicacao.formularioEditarId;
    },
    informacoesTabelaAtivada() {
      return this.tabela.filter(item => item.id == this.idTabelaAtivada)[0];
    },
    imagemMobileBg() {
      return `background-image: url(${this.dataImagemMobile}); background-position: center; background-size: contain; background-repeat: no-repeat;`;
    },
    imagemDesktopBg() {
      return `background-image: url(${this.dataImagemDesk}); background-position: center; background-size: contain; background-repeat: no-repeat;`;
    },
    imagemBg() {
      return `background-image: url(${this.dataImagem}); background-position: center; background-size: contain; background-repeat: no-repeat;`;
    }
  },
  methods: {
    _imagemMobile,
    _imagemDesktop,
    returnValidUrl,
    atualizaCalendario(data) {
      this.form.dataInicio = data.start;
      this.form.dataFinal = data.end;
      this.$v.form.dataInicio.$touch();
      this.$v.form.dataFinal.$touch();
    },
    toggleCalendario() {
      if (this.form.permanente == true) {
        document.querySelector(".vhd-input").disabled = true;
        const calendario = document.querySelector(".close");
        calendario ? document.querySelector(".close").click() : false;
      } else {
        document.querySelector(".vhd-input").disabled = false;
      }
    },
    resetCalendario() {
      this.form.dataInicio = "";
      this.form.dataFinal = "";
      this.$v.form.dataInicio.$touch();
      this.$v.form.dataFinal.$touch();
    },
    closeCalendario() {
      this.$v.form.dataInicio.$touch();
      this.$v.form.dataFinal.$touch();
    },
    fecharSelect(item) {
      if (!this.form[item] || this.form[item].length < 1) {
        this.form[item] = null;
      }
      return this.$v.form[item].$touch();
    },
    atualizarImagem(form, imagem, $event) {
      if (!$event.target.files[0]) {
        this[imagem] = "";
        this.form[form] = "";
        return false;
      }
      this.form[form] = $event.target.files[0];
      const reader = new FileReader();
      reader.onload = bg => {
        this[imagem] = bg.target.result;
      };
      reader.readAsDataURL($event.target.files[0]);
      this.$v.form[form].$touch();
    },
    removerImagem(form, imagem, elem) {
      this[imagem] = "";
      this.form[form] = "";
      document.querySelector(`#${elem}`).value = "";
    },
    cancelarFormuario() {
      this.$store.commit("comunicacao/SET_COMUNICACAO_TELA", "Lista");
    },
    verificaCampoObrigatorio(campo) {
      return this.camposInput.camposObrigatorios.some(item => item == campo);
    },
    montarFomularioEditar() {
      this.form.ordem = this.informacoesTabelaAtivada.ordem; 
      // this.form.tipo = this.informacoesTabelaAtivada.segmentacao == null ? { name: "Geral", id: 1 } : { name: "Participante", id: 2 };
      this.form.url = this.informacoesTabelaAtivada.url;
      this.form.dataInicio = this.informacoesTabelaAtivada.dataInicial
        .split("T")[0]
        .split("-")
        .reverse()
        .join("/");
      this.form.dataFinal = this.informacoesTabelaAtivada.dataFinal
        .split("T")[0]
        .split("-")
        .reverse()
        .join("/");
      this.calendario.inicio = new Date(this.informacoesTabelaAtivada.dataInicial);
      this.calendario.final = new Date(this.informacoesTabelaAtivada.dataFinal);
      this.form.permanente = this.informacoesTabelaAtivada.permanente;
      this.dataImagemMobile = this._imagemMobile(this.informacoesTabelaAtivada.imagemMobile);
      this.dataImagemDesk = this._imagemDesktop(this.informacoesTabelaAtivada.imagemDesktop);
      this.dataImagem = this.informacoesTabelaAtivada.caminhoImagem;
      this.form.unidadeNegocio = this.informacoesTabelaAtivada.unidadeNegocio;
      this.form.redes = this.informacoesTabelaAtivada.redes;
      this.form.cargos = this.informacoesTabelaAtivada.cargos;
      this.form.tagueamentoCategoria = this.informacoesTabelaAtivada.tagCategoria;
      this.form.tagueamentoAcao = this.informacoesTabelaAtivada.tagAcao;
      this.form.conteudo = this.informacoesTabelaAtivada.conteudo;
      this.form.titulo = this.informacoesTabelaAtivada.titulo;
    },
    montarFormData() {
      let formData = new FormData();
      if (this.formularioTipo == "Editar") {
        formData.append("Id", this.idTabelaAtivada);
      }
      this.camposInput.camposDisponiveis.ordem ? formData.append("Ordem", this.form.ordem) : false;
      this.camposInput.camposDisponiveis.url ? formData.append("URL", this.returnValidUrl(this.form.url)) : false;
      if (this.camposInput.camposDisponiveis.data) {
        formData.append("Permanente", this.form.permanente);
        formData.append("DataInicial", this.form.permanente ? "" : stringToDatetime(this.form.dataInicio));
        formData.append("DataFinal", this.form.permanente ? "" : stringToDatetime(this.form.dataFinal));
      }
      if (this.camposInput.camposDisponiveis.tagueamento) {
        formData.append("TagCategoria", this.form.tagueamentoCategoria);
        formData.append("TagAcao", this.form.tagueamentoAcao);
      }
      if (this.camposInput.camposDisponiveis.tipo) {
        formData.append("Segmentacao.Id", this.form.tipo.id);
      }
      // this.camposInput.camposDisponiveis.bannerDesk ? formData.append("imagemDesktop", this.form.imagemDesk) : false;
      // if (this.formularioTipo == "Novo" && this.camposInput.camposDisponiveis.bannerMobile) {
      //   formData.append("imagemMobile", this.form.imagemMobile ? this.form.imagemMobile : this.form.imagemDesk);
      // } else if (this.formularioTipo == "Editar" && this.camposInput.camposDisponiveis.bannerMobile) {
      //   formData.append("imagemMobile", this.dataImagemMobile ? this.form.imagemMobile : this.form.imagemDesk);
      // }

      if (this.camposInput.camposDisponiveis.bannerMobile) {
        if (this.formularioTipo == "Editar") {
          formData.append("imagemMobile", this.form.imagemMobile ? this.form.imagemMobile : null);
        } else {
          formData.append("imagemMobile", this.form.imagemMobile);
        }
      }

      if (this.camposInput.camposDisponiveis.bannerDesk) {
        if (this.formularioTipo == "Editar") {
          formData.append("imagemDesktop", this.form.imagemDesktop ? this.form.imagemDesktop : null);
        } else {
          formData.append("imagemDesktop", this.form.imagemDesktop);
        }
      }


      this.camposInput.camposDisponiveis.banner ? formData.append("imagem", this.form.imagem) : false;

      if (this.camposInput.camposDisponiveis.conteudo) {
        formData.append("Conteudo", this.form.conteudo);
      }
      if (this.camposInput.camposDisponiveis.titulo) {
        formData.append("Titulo", this.form.titulo);
      }

      if (this.formularioTipo != "Editar") {
        formData.append("Ativo", true);
      }
      formData.append("TipoComunicacao.Id", 1); // Mockado para "Banner" relação com a tabela "TipoComunicacao"
      return formData;
    },
    salvarFormuario() {
      if (this.formularioTipo == "Editar") {
        comunicacaoAtualizar(this.montarFormData(), this.camposInput.apiEditar);
      } else {
        comunicacaoEnviar(this.montarFormData(), this.camposInput.apiEnviar);
      }
    }
  },
  watch: {
    "form.permanente": {
      deep: true,
      handler: function() {
        this.toggleCalendario();
      }
    }
  },
  created() {
    if (this.formularioTipo == "Editar") {
      this.montarFomularioEditar();
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
