export function isValidUrl(string) {
    try {
        new URL(string);
    } catch (_) {
        return false;  
    }
    return true;
}

export function returnValidUrl(string) {
    return isValidUrl(string) ? string : `http://${string}`;
}