<template>
  <div>
    <transition name="fade-left" mode="out-in">
      <div class="comunicacao" v-if="formOuLista == 'Lista'" key="Lista">
        <!-- <BotoesAbas :componentes="componentesMenu" /> -->
        <Tabela />
      </div>
      <div v-else class="comunicacao-formulario" key="Formulario">
        <FormularioIncluirEditar />
      </div>
    </transition>
  </div>
</template>

<script>
import Tabela from "@/components/comunicacao/Tabela.vue";
import * as api from "@/api/_caminhosApi";
import FormularioIncluirEditar from "@/components/comunicacao/FormularioIncluirEditar.vue";
// import BotoesAbas from "@/components/comunicacao/BotoesAbas.vue";
export default {
  components: {
    // BotoesAbas,
    Tabela,
    FormularioIncluirEditar
  },
  data() {
    return {
      tela: "",
      comunicacoes: [
        {
          id: 1,
          ativado: true,
          titulo: "Banners",
          componente: "Banners",
          apiBuscar: api._rotaBuscarBanners(),
          apiExcluir: api._rotaExcluirBanners(),
          apiEditar: api._rotaAtualizarBanners(),
          apiEnviar: api._rotaEnviarBanners(),
          camposDisponiveis: {
            ordem: true,
            tipo: false,
            url: true,
            data: true,
            bannerMobile: true,
            bannerDesk: true,
            banner: false,
            tagueamento: true,
            conteudo: true,
            titulo: false
          },
          camposObrigatorios: ["ordem", "tipo", "data", "bannerDesk"]
        },
        // {
        //   id: 2,
        //   ativado: true,
        //   titulo: "Popup",
        //   componente: "Popup",
        //   apiBuscar: api._rotaBuscarPopup(),
        //   apiExcluir: api._rotaExcluirPopup(),
        //   apiEditar: api._rotaAtualizarPopup(),
        //   apiEnviar: api._rotaEnviarPopup(),
        //   camposDisponiveis: {
        //     ordem: true,
        //     tipo: true,
        //     url: true,
        //     data: true,
        //     bannerMobile: true,
        //     bannerDesk: true,
        //     banner: false,
        //     tagueamento: false,
        //     conteudo: false,
        //     titulo: false
        //   },
        //   camposObrigatorios: ["ordem", "tipo", "data", "bannerDesk"]
        // },
        // {
        //   id: 3,
        //   ativado: true,
        //   titulo: "Fique Ligado",
        //   componente: "FiqueLigado",
        //   apiBuscar: api._rotaBuscarFiqueLigado(),
        //   apiExcluir: api._rotaExcluirFiqueLigado(),
        //   apiEditar: api._rotaAtualizarFiqueLigado(),
        //   apiEnviar: api._rotaEnviarFiqueLigado(),
        //   camposDisponiveis: {
        //     ordem: false,
        //     tipo: true,
        //     url: true,
        //     data: true,
        //     bannerMobile: false,
        //     bannerDesk: false,
        //     banner: true,
        //     tagueamento: false,
        //     conteudo: true,
        //     titulo: false
        //   },
        //   camposObrigatorios: ["tipo", "data", "banner", "conteudo"]
        // }
      ]
    };
  },
  computed: {
    formOuLista() {
      return this.$store.state.comunicacao.telaAtivada;
    },
    componentesMenu() {
      return this.comunicacoes.filter(item => item.ativado);
    }
  },
  created() {
    this.$store.commit("comunicacao/SET_LINKATIVADO", this.componentesMenu[0].componente);
    this.$store.commit("comunicacao/SET_COMPONENTE", this.componentesMenu[0]);
    this.$store.commit("comunicacao/SET_COMUNICACAO_TELA", "Lista");
  },
  watch: {
    formOuLista() {
      if (this.$store.state.interacoes.modal.modalAtivado) {
        this.$store.commit("interacoes/SET_ALERTA", {
          ativado: false,
          mensagem: "",
          tipo: ""
        });
      }
    },
    componentesMenu() {
      if (this.$store.state.interacoes.modal.modalAtivado) {
        this.$store.commit("interacoes/SET_ALERTA", {
          ativado: false,
          mensagem: "",
          tipo: ""
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.comunicacao {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.comunicacao-tabela {
  width: 100% !important;
}
.comunicacao-formulario {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 25px;
  @media (max-width: 750px) {
    padding: 25px 10px;
  }
}
</style>
