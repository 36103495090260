// export function formErroDefault(item) {
//   let erro = "";
//   const itemForm = item;
//   if (!itemForm.$dirty) {
//     return erro;
//   }
//   if (!itemForm.required) {
//     erro = "Obrigatório";
//   }
//   return erro;
// }

export function ordemErro() {
  let erro = "";
  const ordem = this.$v.form.ordem;
  if (!ordem.$dirty) {
    return erro;
  }
  if (!ordem.required) {
    erro = "Obrigatório";
  }
  return erro;
}
export function tipoErro() {
  let erro = "";
  const ordem = this.$v.form.tipo;
  if (!ordem.$dirty) {
    return erro;
  }
  if (!ordem.required) {
    erro = "Obrigatório";
  }
  return erro;
}
export function urlErro() {
  let erro = "";
  const url = this.$v.form.url;
  if (!url.$dirty) {
    return erro;
  }
  if (!url.required) {
    erro = "Obrigatório";
  }
  return erro;
}
export function dataErro() {
  let erro = "";
  const dataInicio = this.$v.form.dataInicio;
  const dataFinal = this.$v.form.dataFinal;
  if (!dataInicio.$dirty || !dataFinal.$dirty) {
    return erro;
  }
  if (!dataInicio.required || !dataFinal.required) {
    erro = "Obrigatório";
  }
  return erro;
}
export function unidadeNegocioErro() {
  let erro = "";
  const unidadeNegocio = this.$v.form.unidadeNegocio;
  if (!unidadeNegocio.$dirty) {
    return erro;
  }
  if (!unidadeNegocio.required) {
    erro = "Obrigatório";
  }
  return erro;
}
export function redesErro() {
  let erro = "";
  const redes = this.$v.form.redes;
  if (!redes.$dirty) {
    return erro;
  }
  if (!redes.required) {
    erro = "Obrigatório";
  }
  return erro;
}
export function cargosErro() {
  let erro = "";
  const cargos = this.$v.form.cargos;
  if (!cargos.$dirty) {
    return erro;
  }
  if (!cargos.required) {
    erro = "Obrigatório";
  }
  return erro;
}
export function imagemMobileErro() {
  let erro = "";
  const imagemMobile = this.$v.form.imagemMobile;
  if (!imagemMobile.$dirty) {
    return erro;
  }
  if (!imagemMobile.required) {
    erro = "Obrigatório";
  }
  return erro;
}
export function imagemDesktopErro() {
  let erro = "";
  const imagemDesk = this.$v.form.imagemDesk;
  if (!imagemDesk.$dirty) {
    return erro;
  }
  if (!imagemDesk.required) {
    erro = "Obrigatório";
  }
  return erro;
}
export function imagemErro() {
  let erro = "";
  const imagem = this.$v.form.imagem;
  if (!imagem.$dirty) {
    return erro;
  }
  if (!imagem.required) {
    erro = "Obrigatório";
  }
  return erro;
}
export function categoriaErro() {
  let erro = "";
  const categoria = this.$v.form.tagueamentoCategoria;
  if (!categoria.$dirty) {
    return erro;
  }
  if (!categoria.required) {
    erro = "Obrigatório";
  }
  return erro;
}
export function acaoErro() {
  let erro = "";
  const acao = this.$v.form.tagueamentoAcao;
  if (!acao.$dirty) {
    return erro;
  }
  if (!acao.required) {
    erro = "Obrigatório";
  }
  return erro;
}
export function tituloErro() {
  let erro = "";
  const titulo = this.$v.form.titulo;
  if (!titulo.$dirty) {
    return erro;
  }
  if (!titulo.required) {
    erro = "Obrigatório";
  }
  return erro;
}
export function conteudoErro() {
  let erro = "";
  const conteudo = this.$v.form.conteudo;
  if (!conteudo.$dirty) {
    return erro;
  }
  if (!conteudo.required) {
    erro = "Obrigatório";
  }
  return erro;
}
