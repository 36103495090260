<template>
  <div class="comunicacao-tabela">
    <div class="tabela-titulo">
      <h1>{{ camposInput.titulo }}</h1>
      <button class="btn btn-novo-conteudo" @click="novaComunicacao">+ Novo</button>
    </div>
    <transition name="fade-left" mode="out-in">
      <div v-if="semDados" class="tabela-sem-dados" key="Vazio">
        <p>Sem dados no momento</p>
      </div>
      <div v-else class="tabela-informacoes" key="Lista">
        <div class="tabela-linha tabela-linha-titulo">
          <div v-if="camposInput.camposDisponiveis.ordem" class="tabela-linha-ordem"><p>Ordem</p></div>
          <div v-if="camposInput.camposDisponiveis.bannerMobile" class="tabela-linha-mobile"><p>Mobile</p></div>
          <div v-if="camposInput.camposDisponiveis.bannerDesk" class="tabela-linha-desktop"><p>Desktop</p></div>
          <div v-if="camposInput.camposDisponiveis.banner" class="tabela-linha-imagem"><p>Imagem</p></div>
          <div v-if="camposInput.camposDisponiveis.url" class="tabela-linha-url"><p>URL</p></div>
          <div v-if="camposInput.camposDisponiveis.data" class="tabela-linha-permanente"><p>Permanente</p></div>
          <div v-if="camposInput.camposDisponiveis.data" class="tabela-linha-inicio"><p>Início</p></div>
          <div v-if="camposInput.camposDisponiveis.data" class="tabela-linha-fim"><p>Fim</p></div>
          <div class="tabela-linha-btns"></div>
        </div>
        <div class="tabela-linha tabela-linha-informacoes" v-for="itemTabela in dadosTabela" :key="itemTabela.id">
          <div v-if="camposInput.camposDisponiveis.ordem" class="tabela-linha-ordem">
            <p>{{ itemTabela.ordem }}</p>
          </div>
          <div v-if="camposInput.camposDisponiveis.bannerMobile" class="tabela-linha-mobile"><a :href="_imagemMobile(itemTabela.imagemMobile)" target="_blank" :style="`background: url(${_imagemMobile(itemTabela.imagemMobile)}) center center / contain no-repeat;`"></a></div>
          <div v-if="camposInput.camposDisponiveis.bannerDesk" class="tabela-linha-desktop"><a :href="_imagemDesktop(itemTabela.imagemDesktop)" target="_blank" :style="`background: url(${_imagemDesktop(itemTabela.imagemDesktop)}) center center / contain no-repeat;`"></a></div>
          <div v-if="camposInput.camposDisponiveis.banner" class="tabela-linha-imagem"><a :href="itemTabela.imagemMobile" target="_blank" :style="`background: url(${itemTabela.caminhoImagem}) center center / contain no-repeat;`"></a></div>
          <div v-if="camposInput.camposDisponiveis.url" class="tabela-linha-url">
            <a :href="itemTabela.url" target="_blank" rel="noopener noreferrer">Clique aqui</a>
          </div>
          <div v-if="camposInput.camposDisponiveis.data" class="tabela-linha-permanente">
            <p>{{ itemTabela.permanente ? "Sim" : "Não" }}</p>
          </div>
          <div v-if="camposInput.camposDisponiveis.data" class="tabela-linha-inicio">
            <p>{{ itemTabela.dataInicial | filterData }}</p>
          </div>
          <div v-if="camposInput.camposDisponiveis.data" class="tabela-linha-fim">
            <p>{{ itemTabela.dataFinal | filterData }}</p>
          </div>
          <div class="tabela-linha-btns">
            <div class="tabela-linha-btns-editar" @click="editarItem(itemTabela.id)">
              <img src="~@/assets/images/icones/editar.svg" alt="Editar" />
            </div>
            <div class="tabela-linha-btns-excluir" @click="excluirItem(itemTabela.id)">
              <img src="~@/assets/images/icones/excluir.svg" alt="Excluir" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { comunicacaoBusca, comunicacaoExcluir } from "@/api/comunicacao";
import { _imagemMobile, _imagemDesktop } from "@/api/_caminhosApi";
import { filterData } from "@/utils/date";
export default {
  data() {
    return {
      semDados: true
    };
  },
  computed: {
    camposInput() {
      return this.$store.state.comunicacao.componenteAtivado;
    },
    dadosTabela() {
      console.log('dadosTabela', this.$store.state.comunicacao.tabela)
      return this.$store.state.comunicacao.tabela;
    }
  },
  methods: {
    _imagemMobile,
    _imagemDesktop,
    novaComunicacao() {
      this.$store.commit("comunicacao/SET_TIPO_FORMULARIO", "Novo");
      this.$store.commit("comunicacao/SET_COMUNICACAO_TELA", "Formulario");
    },
    verificaComponente() {
      const metodo = this.camposInput.componente;
      switch (metodo) {
        case "Banners":
          return comunicacaoBusca(this.camposInput.apiBuscar);
        default:
          this.$store.commit("comunicacao/SET_TABELA", "");
          this.semDados = true;
          break;
      }
    },
    editarItem(id) {
      this.$store.commit("comunicacao/SET_TIPO_FORMULARIO", "Editar");
      this.$store.commit("comunicacao/SET_COMUNICACAO_TELA", "Formulario");
      this.$store.commit("comunicacao/SET_ID_FORMULARIO_EDITAR", id);
    },
    excluirItem(id) {
      confirm("Tem certeza que deseja excluir?") ? comunicacaoExcluir(id, this.camposInput.apiExcluir) : false;
    }
  },
  filters: {
    filterData
  },
  mounted() {
    this.verificaComponente();
  },
  watch: {
    camposInput() {
      this.verificaComponente();
    },
    dadosTabela() {
      this.dadosTabela.length > 0 ? (this.semDados = false) : (this.semDados = true);
    }
  }
};
</script>
