import axios from "axios";
import client from "@/api";
import store from "@/store";
import { getFirstError } from "@/utils/errorResponse";

export function comunicacaoBusca(rotaBuscar) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("comunicacao/SET_TABELA", "");
  client
    .get(`${rotaBuscar}`)
    .then(resp => {
      const items = resp.data.filter(item => item.ativo === true);
      store.commit("comunicacao/SET_TABELA", items);
    })
    .catch(erro => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
//
export function comunicacaoExcluir(id, rotaExcluir) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .post(`${rotaExcluir}?comunicacaoId=${id}`)
    .then(() => {
      const dadosTabela = store.state.comunicacao.tabela;
      const tabelaSemItemDoId = dadosTabela.filter(item => item.id != id);
      store.commit("comunicacao/SET_TABELA", tabelaSemItemDoId);
      store.commit("interacoes/SET_ALERTA", {
        ativado: true,
        mensagem: "Excluído com sucesso!",
        tipo: "sucesso"
      });
    })
    .catch(erro => {
      console.log(erro);
      store.commit("interacoes/SET_ALERTA", {
        ativado: true,
        mensagem: "Erro ao excluir.",
        tipo: "erro"
      });
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
//
export function comunicacaoAtualizar(formData, rotaAtualizar) {
  console.log("Formulario Editar:", [...formData]);
  store.commit("interacoes/SET_LOADING", true);
  axios({
    method: "POST",
    url: `${process.env.VUE_APP_API_BASE}${rotaAtualizar}`,
    data: formData,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
  })
    .then(() => {
      store.commit("interacoes/SET_ALERTA", {
        ativado: true,
        mensagem: "Alterado com sucesso!",
        tipo: "sucesso"
      });
      store.commit("comunicacao/SET_COMUNICACAO_TELA", "Lista");
    })
    .catch(erro => {
      console.log(erro);
      store.commit("interacoes/SET_ALERTA", {
        ativado: true,
        mensagem: "Erro ao alterar",
        tipo: "erro"
      });
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
//
export function comunicacaoEnviar(formData, rotaEnviar) {
  console.log("Novo formulário:", [...formData]);
  store.commit("interacoes/SET_LOADING", true);
  client
    .post(rotaEnviar, formData)
    .then(() => {
      store.commit("interacoes/SET_ALERTA", {
        ativado: true,
        mensagem: "Salvo com sucesso!",
        tipo: "sucesso"
      });
      store.commit("comunicacao/SET_COMUNICACAO_TELA", "Lista");
    })
    .catch(erro => {
      console.log(erro);
      store.commit("interacoes/SET_ALERTA", {
        ativado: true,
        mensagem: erro.response.data ? getFirstError(erro.response.data) : "Erro inesperado. Informe a área técnica.",
        tipo: "erro"
      });
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
